<template xmlns:>
  <div class="container">
    <div class="handle-box">

    <el-form ref="form" :inline="true">
      <el-form-item label="课程名">
        <el-input
          placeholder="请输入课程名称"
          v-model="courseTable.title"
        ></el-input>
      </el-form-item>
      <el-form-item label="分类">
        <el-select  v-model="classification"  placeholder="请选择分类">
            <el-option
              v-for="(item,index) in courseCate"
              :key="index"
              :label="item.title"
              :value="item.id">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="老师">
        <el-select  v-model="teacher_id"  placeholder="请选择老师">
            <el-option
              v-for="(item,index) in selectTeacher"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间">
         <el-date-picker
          v-model="courseTable.search_date"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="searchCouseList" icon="el-icon-search"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item class="generate">
        <el-button type="primary" @click="toAdd">添加课程</el-button>
      </el-form-item>
    </el-form>
   </div>
    <el-table
      :data="courseTable.data"
      border
      class="table"
      ref="multipleSelection"
      @selection-change="handleSelectionChange"
      v-loading="loading"
    >
      <el-table-column prop="id" label="编号" width="100" align="center"></el-table-column>
      <el-table-column prop="goods_name" label="课程名" align="center"></el-table-column>
      <el-table-column prop="teacher_name" label="老师" align="center"></el-table-column>
      <el-table-column label="封面图" align="center">
        <template slot-scope="scope">
          <el-image
            class="table-td-thumb"
            :src="scope.row.thumb"
            :preview-src-list="[scope.row.thumb]"
          ></el-image>
        </template>
      </el-table-column>

      <el-table-column prop="cate" label="课程分类" align="center" width="100"></el-table-column>
      <el-table-column label="过期时间" align="center" width="150">

        <template slot-scope="scope">
          <div v-if="scope.row.expire !== null">
            {{
              $moment(scope.row.expire * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </div>
          <div v-else>空</div>
        </template>
      </el-table-column>

      <el-table-column label="是否公开课" align="center" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.is_open=='1'?'success':'danger'">
            <div v-if="scope.row.is_open == 1">是</div>
            <div v-else>否</div>
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="是否上架" align="center" width="120">
        <template slot-scope="scope">
          <el-tag :type="scope.row.is_enable=='1'?'success':'danger'">
            <div v-if="scope.row.is_enable == 1">上架</div>
            <div v-else>下架</div>
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope" >
          <div style="display:flex;justify-content:space-between;padding:0px 20px;">
            <!-- <el-button type="primary" icon="el-icon-edit" circle @click="handleItemEdit(scope.$index, scope.row)" size="small"></el-button>
            <el-button type="primary" icon="el-icon-tickets" size="small" circle  @click="handleItemChapter(scope.$index, scope.row)"></el-button>
            <el-button type="success" icon="el-icon-connection" size="small" circle @click="toCopyCourse(scope.row)"></el-button>
            <el-button type="info" icon="el-icon-s-custom" size="small" circle @click="toViewDetails(scope.row,2)"></el-button>
            <el-button type="warning" icon="el-icon-link" size="small" circle  @click="openLink(scope.row.ranking_url)"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="small" circle @click="handleItemDelete(scope.$index, scope.row)"></el-button> -->
            <!-- <el-button type="success" icon="el-icon-info" size="small" circle @click="toViewDetails(scope.row,1)"></el-button> -->
            <el-badge :is-dot="false" style="margin-top:5px;" class="item">
              <el-tooltip class="item" effect="dark" content="编辑" placement="bottom">
                <el-button type="primary" icon="el-icon-edit" circle @click="handleItemEdit(scope.$index, scope.row)" size="small"></el-button>
              </el-tooltip>
            </el-badge>
            <el-badge :is-dot="false" style="margin-top:5px;" class="item">
              <el-tooltip class="item" effect="dark" content="章节" placement="bottom">
                <el-button type="primary" icon="el-icon-tickets" size="small" circle  @click="handleItemChapter(scope.$index, scope.row)"></el-button>
              </el-tooltip>
            </el-badge>
            <el-badge :is-dot="false" style="margin-top:5px;" class="item">
              <el-tooltip class="item" effect="dark" content="复制" placement="bottom">
                <el-button type="success" icon="el-icon-connection" size="small" circle @click="toCopyCourse(scope.row)"></el-button>
              </el-tooltip>
            </el-badge>
            <el-badge :is-dot="scope.row.home_work_tips == 0 ? false : true" style="margin-top:5px;" class="item">
              <el-tooltip class="item" effect="dark" content="作业" placement="bottom">
                <el-button type="info" icon="el-icon-s-custom" size="small" circle @click="toViewDetails(scope.row,2)"></el-button>
              </el-tooltip>
            </el-badge>
            <el-badge :is-dot="false" style="margin-top:5px;" class="item">
              <el-tooltip class="item" effect="dark" content="排行" placement="bottom">
                <el-button type="warning" icon="el-icon-link" size="small" circle  @click="openLink(scope.row.ranking_url)"></el-button>
              </el-tooltip>
            </el-badge>
            <el-badge :is-dot="false" style="margin-top:5px;" class="item">
              <el-tooltip class="item" effect="dark" content="删除" placement="bottom">
                <el-button type="danger" icon="el-icon-delete" size="small" circle @click="handleItemDelete(scope.$index, scope.row)"></el-button>
              </el-tooltip>
            </el-badge>
          </div>
        </template>
      </el-table-column>
    </el-table>


    <!-- 分页 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="courseTable.page"
        :page-sizes="[10, 15, 20, 50]"
        :page-size="courseTable.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="courseTable.total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 详情弹框 -->
      <el-dialog title="详情" :visible.sync="detailsDialog" width="50%">
        <el-form :model="detailsData">
          <el-form-item label="ID：" label-width="100px">
            {{ detailsData.id }}
          </el-form-item>

          <el-form-item label="课程分类：" label-width="100px">
          {{detailsData.cate.title}}
          </el-form-item>
          <el-form-item label="课程名：" label-width="100px">
            {{ detailsData.goods_name }}
          </el-form-item>
          <el-form-item label="描述：" label-width="100px">
            {{detailsData.desc}}
          </el-form-item>
          <el-form-item label="价格：" label-width="100px">
            {{ detailsData.price }}
          </el-form-item>
          <el-form-item label="到期时间：" label-width="100px">
            {{
              $moment(detailsData.expire * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </el-form-item>
          <el-form-item label="浏览量：" label-width="100px">
            {{detailsData.views}}
          </el-form-item>
          <el-form-item label="购买量：" label-width="100px">
            {{detailsData.buy_num}}
          </el-form-item>
          <el-form-item label="是否一对一：" label-width="100px">
            {{detailsData.type==1?'否':'是'}}
          </el-form-item>
          <el-form-item label="是否公开课：" label-width="100px">
            {{detailsData.is_open==0?'否':'是'}}
          </el-form-item>
          <el-form-item label="是否精品：" label-width="100px">
            {{detailsData.is_best==0?'否':'是'}}
          </el-form-item>
          <el-form-item label="是否热销：" label-width="100px">
              {{detailsData.is_hot==0?'否':'是'}}
          </el-form-item>
          <el-form-item label="是否新品：" label-width="100px">
            {{detailsData.is_new==0?'否':'是'}}
          </el-form-item>
          <el-form-item label="是否上架：" label-width="100px">
            {{detailsData.is_enable==0?'否':'是'}}
          </el-form-item>
          <el-form-item label="每节需金币：" label-width="100px" v-if="detailsData.type==2">
            {{detailsData.score}}
          </el-form-item>
          <el-form-item label="内容：" label-width="100px">
           <div v-html="detailsData.content" class="detailImg"></div>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 班级学生列表弹框 -->
      <el-dialog title="学员列表" :visible.sync="studentsDialog" width="60%">
        <div style="overflow:auto;height:600px">
          <el-table :data="detailsData.user_list" border class="table" ref="multipleSelection" @selection-change="handleSelectionChange" v-loading="loading">
            <el-table-column prop="user_id" label="用户ID" width="100" align="center"></el-table-column>
            <el-table-column prop="nickname" label="昵称" align="center"></el-table-column>
            <el-table-column prop="remark" label="备注" align="center"></el-table-column>
            <el-table-column prop="phone" label="手机号" align="center" ></el-table-column>
            <el-table-column prop="study_info.chapter_name" label="学习进度" align="center" ></el-table-column>
            <el-table-column prop="learn_num" label="需提交数" align="center" ></el-table-column>
            <el-table-column prop="work_num" label="总提交数" align="center" >
              <template slot-scope="scope">
                <div :style="{ color: scope.row.learn_num > scope.row.work_num? 'red' : 'black' }">
                  {{ scope.row.work_num }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="reply_num" label="已回复数" align="center" >
              <template slot-scope="scope">
                <div :style="{ color: scope.row.work_num > scope.row.reply_num? 'red' : 'black' }">
                  {{ scope.row.reply_num }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope" >
                <el-button type="primary" icon="el-icon-view" circle @click="toStudentDetail(scope.row)" size="small"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>

      <!-- 学生详情弹框 -->
      <el-dialog title="学员学习进度" :visible.sync="studentDetailDialog" width="50%">  
        <div style="overflow:auto;height:600px">
          <el-table :data="studentDetailsData" border class="table" ref="multipleSelection" @selection-change="handleSelectionChange" v-loading="loading">
            <el-table-column prop="goodsLibChapter.chapter_name" label="章节名"  align="center"></el-table-column>
            <el-table-column prop="finish_learn" label="观看进度" align="center" width="90">
              <template slot-scope="scope">
                <el-tag :type="scope.row.finish_learn=='1'?'success':'danger'">
                  <div v-if="scope.row.finish_learn == 1">已观看</div>
                  <div v-else>未观看</div>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="是否有作业" align="center" width="90">
              <template slot-scope="scope">
                <el-tag :type="scope.row.goodsLibChapter.is_task=='1'?'success':'danger'">
                  <div v-if="scope.row.goodsLibChapter.is_task == 1">是</div>
                  <div v-else>否</div>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="study_info.chapter_name" label="作业记录" align="center" width="90">
              <template slot-scope="scope">
                <el-tag :type="scope.row.finish_task=='1'?'success':'danger'">
                  <div v-if="scope.row.finish_task == 1">已提交</div>
                  <div v-else>未提交</div>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="task_time" label="提交时间" align="center" width="90">
              <template slot-scope="scope">
                <div v-if="scope.row.task_time == 0">
                  --
                </div>
                <div v-else-if="scope.row.task_time !== 0">
                  {{
                    $moment(scope.row.task_time * 1000).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}
                </div>
                <div v-else>空</div>
              </template>
            </el-table-column>
            <el-table-column prop="reply" label="是否回复" align="center" width="90">
              <template slot-scope="scope">
                <el-tag :type="scope.row.reply=='1'?'success':'danger'">
                  <div v-if="scope.row.reply == 0">未回复</div>
                  <div v-else>已回复</div>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="90">
              <template slot-scope="scope" >
                <el-button type="primary" icon="el-icon-view" circle @click="toWorkDetail(scope.row)" size="small"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>

      <!-- 学生作业详情弹框 -->
      <el-dialog title="学生作业详情" :visible.sync="workDetailDialog" width="40%" @close="closeWorkDetailDialog">  
        <div style="overflow:auto;height:600px">
          <el-form
            label-width="70px"
            style="margin-right:20px"
          >
            <el-form-item
              el-form-item
              label="学生留言"
              label-width="70px"
            >
              <div class="op3" v-if="reply_message == null" style="background-color:#fff;border:1px solid #ddd;border-radius:5px;padding:0px 10px">无</div>
              <div class="op3" v-else style="background-color:#fff;border:1px solid #ddd;border-radius:5px;padding:0px 10px">{{reply_message}}</div>
            </el-form-item>
            <el-form-item
              el-form-item
              label="老师回复"
              label-width="70px"
            >
              <el-input
                v-model="teacherReply"
                placeholder="请输入回复内容"
                show-word-limit
                type="textarea"
                :rows="3"
              />
            </el-form-item>
            <el-button type="primary" @click="reply" v-if="is_reply == 0" style="float:right;">回复</el-button>
            <el-button type="primary" :disabled="true" v-else style="float:right;">已回复</el-button>
          </el-form>
          <div class="questionAns">
            <div v-for="(item,index) in workDetailsData" class="questionBox">
              <div class="questionNum">第{{Number(index)+1}}题</div>
              <!-- 单选、多选 -->
              <div v-if="item.title_class == 'select' && item.title_type == 'word' && item.ans_type == 'word' ||
                item.title_class == 'mul_select' && item.title_type == 'word' && item.ans_type == 'word'" class="questionItem">
                <div>{{item.title}}</div>
                <div v-for="(item1,key,index) in JSON.parse(item.options)">
                  <!-- <div v-if="key == item.answer" :class="key == item.right?'op1':'op2'">{{key}}.{{item1}}</div> -->
                  <div v-if="item.answer.split(',').indexOf(key) > -1" :class="item.right.split(',').indexOf(key) > -1?'op1':'op2'">
                    {{item1}}
                    <i :class="item.right.split(',').indexOf(key) > -1?'el-icon-circle-check':'el-icon-circle-close'"></i>
                    </div>
                  <div v-else class="op3">{{item1}}</div>
                </div>
                <div>答案：{{item.right}}</div>
              </div>
              <!-- 填空 -->
              <div v-if="item.title_class == 'answer' && item.title_type == 'word' && item.ans_type == 'word'" class="questionItem">
                <div>{{item.title}}</div>
                <div class="op3">{{item.answer}}</div>
                <div>答案：{{item.right}}</div>
              </div>
              <!-- 音选 -->
              <div v-if="item.title_class == 'mul_select' && item.title_type == 'audio'" class="questionItem">
                <audio
                  :src="item.http_title"
                  style="cursor: pointer;outline: none;display:block;"
                  controls="controls"
                  ref="audioPlay"
                ></audio>
                <div v-for="(item1,key,index) in JSON.parse(item.options)">
                  <div v-if="key == item.answer" :class="key == item.right?'op1':'op2'">
                    {{item1}}
                    <i :class="item.right.split(',').indexOf(key) > -1?'el-icon-circle-check':'el-icon-circle-close'"></i>
                  </div>
                  <div v-else class="op3">{{item1}}</div>
                </div>
                <div>答案：{{item.right}}</div>
              </div>
              <!-- 音填 -->
              <div v-if="item.title_class == 'answer' && item.title_type == 'audio'" class="questionItem">
                <audio
                  :src="item.http_title"
                  style="cursor: pointer;outline: none;display:block;"
                  controls="controls"
                  ref="audioPlay"
                ></audio>
                <div class="op3">{{item.answer}}</div>
                <div>答案：{{item.right}}</div>
              </div>
              <!-- 图片 -->
              <div v-if="item.ans_type == 'pic'" class="questionItemText">
                <div class="op4">{{item.title_desc}}</div>
                <div v-if="item.titles.length > 0">
                  <div v-for="item2 in item.titles" style="width:30%;display:inline-block;vertical-align:middle;margin-right:3%">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="item2"
                      alt=""
                      style="width:100%;"
                    />
                  </div>
                </div>
                <div style="width:30%;display:inline-block;vertical-align:middle;margin-right:3%" v-else>
                  <img
                      class="el-upload-list__item-thumbnail"
                      :src="item.http_title"
                      alt=""
                      style="width:100%;"
                    />
                </div>
                <div class="op4">学生答案：</div>
                <div v-if="item.ans.length > 0">
                  <div v-for="item3 in item.ans" style="width:30%;display:inline-block;vertical-align:middle;margin-right:3%;">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="item3"
                      alt=""
                      style="width:100%;"
                      @click="exhibitionSay(item3)"
                    />
                  </div>
                </div>
                <div style="width:30%;display:inline-block;vertical-align:middle;margin-right:3%;" v-else>
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="item.http_answer"
                    alt=""
                    style="width:100%;"
                    @click="exhibitionSay(item.http_answer)"
                  />
                </div>
              </div>
              <!-- 写作 -->
              <div v-if="item.ans_type == 'text'" class="questionItemText">
                <div class="op4">{{item.title}}</div>
                <div class="op5">{{item.answer}}</div>
              </div>
              <!-- 口语 -->
              <div v-if="item.ans_type == 'audio'" class="questionItem">
                <div>{{item.title}}</div>
                <audio
                  :src="item.http_title"
                  style="cursor: pointer;outline: none;display:block;"
                  controls="controls"
                  ref="audioPlay"
                ></audio>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 点击素材弹框 -->
      <el-dialog :visible.sync="exhibition" width="850px" center>
        <img :src="ansPic" alt="" class="exhibition">
      </el-dialog>

        <!-- 选择素材小节位置 -->
        <el-dialog :visible.sync="popup" width="60%"  title="选择素材">
          <div class="search">
            <span style="margin-right: 10px;">素材分类</span>
            <el-select clearable  v-model="sucai.cate_id" filterable placeholder="请选择素材分类" @change="getMaterialClassification">
              <el-option
                v-for="(item,index) in MaterialLists"
                :key="index"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
            <div class="search-left">
              <el-form :inline="true" label-width="80px" ref="search">
                <el-form-item label-width="100px" label="素材名">
                  <el-input
                    placeholder="请输入素材名"
                    v-model="sucai.file_name"
                    @input="Input"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getMaterialClassification">重置</el-button>
                </el-form-item>
              </el-form>
          </div>
        </div>

        <div class="material" @click="selectImg(item)" v-for="(item,index) in materialListes" :key="index">
          <li :class="{active:selecteds==item}">
            <div class="tag" v-if="item.is_interaction == 1">互动课</div>
            <img
              src="../../assets/img/mp3.jpg"
              alt=""
              v-if="item.file_type === 'audio'"
            />
            <img :src="item.avthumb" alt="" v-else />
            <p>{{ item.file_name }}</p>
          </li>
          <div class="types">
            <div class="types-left">
              <img
                src="../../assets/img/material.png"
                alt=""
                v-if="item.file_type === 'image'"
              />
              <img
                src="../../assets/img/图层 5.png"
                alt=""
                v-else-if="item.file_type === 'video'"
              />
              <img src="../../assets/img/图层 6.png" alt="" v-else />
              <span>{{ item.file_size }} </span>
            </div>
          </div>
        </div>
         <!-- 分页 -->
        <div class="pagination">
          <span></span>
           <el-pagination
           @size-change="choiceSizeChange"
           @current-change="choiceCurrentChange"
           :current-page="sucai.page"
           :page-sizes="[15, 30, 45, 60]"
           :page-size="sucai.limit"
           layout="total, sizes, prev, pager, next, jumper"
           :total="choiceTotal"
           algin:="center"
         ></el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="popup = false">取 消</el-button>
          <el-button type="primary" @click="addMaterial">确 定</el-button>
        </span>

        </el-dialog>


          <el-dialog :visible.sync="popup2" width="60%" title="选择素材">
           <div class="search">
             <span style="margin-right: 10px;">素材分类:</span>
             <el-select clearable  v-model="sucai.cate_id" filterable placeholder="请选择素材分类" @change="getMaterialClassification">
              <el-option
                v-for="(item,index) in MaterialLists"
                :key="index"
                :label="item.title"
                :value="item.id">
              </el-option>
          </el-select>
             <div class="search-left">
               <el-form :inline="true" label-width="80px" ref="search">
                 <el-form-item label-width="100px" label="素材名">
                   <el-input
                     placeholder="请输入素材名"
                      v-model="sucai.file_name"
                      @input="Input"
                   ></el-input>
                 </el-form-item>
               </el-form>
             </div>
           </div>
           <div class="material" @click="selectImg2(item)" v-for="(item,index) in materialListes" :key="index">
            <li :class="{active:selecteds2==item}">
             <div class="tag" v-if="item.is_interaction == 1">互动课</div>
             <img
               src="../../assets/img/mp3.jpg"
               alt=""
               v-if="item.file_type === 'audio'"
             />
             <img :src="item.avthumb" alt="" v-else />
             <p>{{ item.file_name }}</p>
               </li>
               <div class="types">
                 <div class="types-left">
                   <img
                     src="../../assets/img/material.png"
                     alt=""
                     v-if="item.file_type === 'image'"
                   />
                   <img
                     src="../../assets/img/图层 5.png"
                     alt=""
                     v-else-if="item.file_type === 'video'"
                   />
                   <img src="../../assets/img/图层 6.png" alt="" v-else />
                   <span>{{ item.file_size }} </span>
                 </div>
               </div>
            </div>
             <!-- 分页 -->
            <div class="pagination">
              <span></span>
               <el-pagination
               @size-change="choiceSizeChange"
               @current-change="choiceCurrentChange"
               :current-page="sucai.page"
               :page-sizes="[15, 30, 45, 60]"
               :page-size="sucai.limit"
               layout="total, sizes, prev, pager, next, jumper"
               :total="choiceTotal"
               algin:="center"
             ></el-pagination>
            </div>
           <span slot="footer" class="dialog-footer">
             <el-button @click="popup2 = false">取 消</el-button>
             <el-button type="primary" @click="addMaterial2">确 定</el-button>
           </span>
         </el-dialog>


<!-- 编辑课程列表 -->
   <el-dialog title="编辑课程" :visible.sync="eddDialog.editVisible" :close-on-click-modal=false top="5%" >
    <div v-loading="editVisibleLoading"
    :element-loading-text="editVisibleText"
    element-loading-spinner="el-icon-loading">
        <el-row >
        <el-col :span="4">
          <span style="line-height:50px">课程分类：</span>
        </el-col>
        <el-col :span="20">
          <el-select
            style="height:50px;line-height:50px;width:100%"
            collapse-tags
            v-model="eddDialog.cate_id"
            placeholder="请选择"
       >
            <el-option
              v-for="(item,index) in courseCate"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <span style="height:50px;line-height:50px">课程名称：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="请输入内容"
            v-model="eddDialog.goods_name"
          ></el-input>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">描述：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="请输入内容"
            v-model="eddDialog.desc"
          ></el-input>
        </el-col>
      </el-row>



      <el-row>
        <el-col :span="4">
          <span style="height:50px;line-height:50px">价格：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="0为免费"
            v-model="eddDialog.price"
          ></el-input>
        </el-col>
      </el-row>

        <el-row style="margin: 6px 0 12px 0">
              <el-col :span="4">
                <span>缩略图</span>
              </el-col>
              <el-col :span="20">
                <el-button
                  class="avatar-uploader"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  @click="choiceM('image')"
                  v-model="eddDialog.thumb_id"
                  >
                  <img v-if="imageUrl2" :src="imageUrl2" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-button>
            </el-col>
        </el-row>

            <el-row>
              <el-col :span="4">
                <span style="line-height:50px">过期时间：</span>
              </el-col>
              <el-col :span="20">

                <el-date-picker
                      v-model="eddDialog.expire"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="选择时间"
                      :picker-options="pickerOptions1"
                      >
                    </el-date-picker>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <p style="line-height:50px">浏览量：</p>
              </el-col>
              <el-col :span="20">
                <el-input
                  style="height:50px;line-height:50px;"
                  placeholder="0和不填为免费"
                  v-model="eddDialog.views_vt"
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">
                <p style="line-height:50px">购买数：</p>
              </el-col>
              <el-col :span="20">
                <el-input
                  style="height:50px;line-height:50px;"
                  placeholder="0和不填为免费"
                  v-model="eddDialog.buy_num_vt"
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">
                <span style="line-height:50px">是否一对一：</span>
              </el-col>
              <el-col :span="10">
                <el-radio v-model="eddDialog.is_1V1" :label="2">是</el-radio>
                <el-radio v-model="eddDialog.is_1V1" :label="1">否</el-radio>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">
                <span style="line-height:50px">是否公开课：</span>
              </el-col>
              <el-col :span="10">
                <el-radio v-model="eddDialog.is_open" :label="1">是</el-radio>
                <el-radio v-model="eddDialog.is_open" :label="0">否</el-radio>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">
                <span style="line-height:50px">是否精品：</span>
              </el-col>
              <el-col :span="10">
                <el-radio v-model="eddDialog.is_best" :label="1">是</el-radio>
                <el-radio v-model="eddDialog.is_best" :label="0">否</el-radio>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">
                <span style="line-height:50px">是否热销：</span>
              </el-col>
              <el-col :span="10">
                <el-radio v-model="eddDialog.is_hot" :label="1">是</el-radio>
                <el-radio v-model="eddDialog.is_hot" :label="0">否</el-radio>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">
                <span style="line-height:50px">是否新品：</span>
              </el-col>
              <el-col :span="10">
                <el-radio v-model="eddDialog.is_new" :label="1">是</el-radio>
                <el-radio v-model="eddDialog.is_new" :label="0">否</el-radio>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">
                <span style="line-height:50px">是否上架：</span>
              </el-col>
              <el-col :span="10">
                <el-radio v-model="eddDialog.is_enable" :label=1>上架</el-radio>
                <el-radio v-model="eddDialog.is_enable" :label=0>下架</el-radio>
              </el-col>
            </el-row>

            <el-row v-if="eddDialog.is_1V1 == 2">
              <el-col :span="4">
                <span style="height:50px;line-height:50px">每节金币数：</span>
              </el-col>
              <el-col :span="20">
                <el-input
                  style="height:50px;line-height:50px"
                  placeholder="请输入每节课需要消耗的金币数"
                  v-model="eddDialog.score"
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">
                <span style="line-height:50px">选择老师：</span>
              </el-col>
              <el-col :span="20">
                <el-select
                  style="height:50px;line-height:50px;width:100%"
                  v-model="eddDialog.teacher_id"
                  placeholder="选择"
                  v-loadmore="loadMoreChaptersListData"
                  @change="changeTeache"
                >
                  <el-option
                    v-for="item in selectTeacher"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>

             <el-row>
        <el-col :span="4">
          <span style="height:50px;line-height:50px">详情：</span>
        </el-col>
        <el-col :span="20">
          <el-card style="height: 610px;">
                <quill-editor v-model="eddDialog.content" ref="myQuillEditor" style="height: 500px;" :options="editorOption">
                </quill-editor>
              </el-card>
        </el-col>
      </el-row>

      <div class="footer-btn">
        <span slot="footer" class="dialog-footer" style="margin-top: 20px;">
        <el-button @click="eddDialog.editVisible = false">取 消</el-button>
        <el-button type="primary" @click="editCourse()">确 定</el-button>
        </span>
     </div>

      </div>

    </el-dialog>
    <!-- 章节添加 -->
        <el-dialog
          title="添加章节"
          :visible.sync="addChapter.adddialogVisible"
          style="padding:100px"
          width="50%"
        >
          <el-row>
            <el-col :span="4">
              <span style="line-height:50px">章节名称：</span>
            </el-col>
            <el-col :span="20">
              <el-input
                style="height:50px;line-height:50px"
                placeholder="请输入内容"
                v-model="addChapter.addChapterName"
              ></el-input>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addChapter.adddialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleAddChapter">确 定</el-button>
          </span>
        </el-dialog>


        <!-- //小节添加 -->
        <el-dialog
           title="添加小节"
           :visible.sync="addSectionDialog.addSectionVisible"
           style="padding:100px"
           width="50%"
           @close="closed"
         >
           <el-row>
             <el-col :span="4">
               <span style="line-height:50px">小节名称：</span>
             </el-col>
             <el-col :span="20">
               <el-input
                 style="height:50px;line-height:50px"
                 placeholder="请输入内容"
                 v-model="addSectionDialog.chapter_name"
               ></el-input>
             </el-col>
           </el-row>
           <el-row style="margin: 10px 0 4px 0">
           <el-col :span="4">
             <span>选择文件：</span>
           </el-col>
           <el-col :span="20">
             <el-button
               class="avatar-uploader"
               :show-file-list="false"
               :on-success="handleAvatarSuccess"
               @click="choiceM('video')"
               v-model="addSectionDialog.file_id"
               >
               <!-- <img v-if="SectionImageUrl" :src="SectionImageUrl" class="avatar">
               <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
               <video v-if="check_video" :src="check_video" controls class="avatar"></video>
               <i v-else class="el-icon-plus avatar-uploader-icon"></i>
               <p style="margin-top:10px">{{check_add_file_name}}</p>
             </el-button>
           </el-col>
         </el-row>

         <el-row>
           <el-col :span="4">
             <span style="line-height:50px">解锁时间：</span>
           </el-col>
           <el-col :span="20">
             <el-date-picker
                   v-model="addSectionDialog.unlock_time"
                   type="datetime"
                   value-format="timestamp"
                   placeholder="选择时间"
                   :picker-options="pickerOptions1"
                   >
                 </el-date-picker>
           </el-col>
         </el-row>
         <el-row>
           <el-col :span="4">
             <span style="line-height:50px">有无作业：</span>
           </el-col>
            <el-col :span="20">
             <el-select
                v-model="addSectionDialog.is_task"
                placeholder="请选择有无作业"
                clearable
              >
                <el-option
                  :label="item.value"
                  :value="item.key"
                  v-for="item in isTaskArr"
                  :key="item.key"
                ></el-option>
              </el-select>
           </el-col>
         </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addSectionDialog.addSectionVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleAddSection">确 定</el-button>
        </span>
        </el-dialog>


    <!-- 添加课程 -->
    <el-dialog
      title="添加课程"
      :visible.sync="addDialog.adddialogVisible"
      style="padding:100px"
      width="50%"
      top=0
      :close-on-click-modal=false
      @close="closedAdd"
    >
    <!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"> -->
      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">分类：</span>
        </el-col>
        <el-col :span="20">
           <el-select
             style="height:50px;line-height:50px;width:100%"
             collapse-tags
             v-model="addDialog.cate_id"
             placeholder="请选择"
        >
             <el-option
               v-for="item in courseCate"
               :key="item.id"
               :label="item.title"
               :value="item.id"
             ></el-option>
           </el-select>
         </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">名称：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="请输入内容"
            v-model="addDialog.goods_name"
          ></el-input>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">描述：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="请输入内容"
            v-model="addDialog.desc"
          ></el-input>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <p style="line-height:50px">价格：</p>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px;"
            placeholder="0和不填为免费"
            v-model="addDialog.addPrice"
          ></el-input>
        </el-col>
      </el-row>

      <el-row style="margin: 6px 0 12px 0">
            <el-col :span="4">
              <span>缩略图</span>
            </el-col>
            <el-col :span="20">
              <el-button
                class="avatar-uploader"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                @click="choiceM('image')"
                v-model="addDialog.thumb_id"
                >
                <img v-if="addImages" :src="addImages" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-button>
          </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">过期时间：</span>
        </el-col>
        <el-col :span="20">

          <el-date-picker
                v-model="addDialog.expire"
                type="datetime"
                value-format="timestamp"
                placeholder="选择时间"
                :picker-options="pickerOptions1"
                >
              </el-date-picker>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <p style="line-height:50px">浏览量：</p>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px;"
            placeholder="0和不填为免费"
            v-model="addDialog.views_vt"
          ></el-input>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <p style="line-height:50px">购买数：</p>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px;"
            placeholder="0和不填为免费"
            v-model="addDialog.buy_num_vt"
          ></el-input>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">是否一对一：</span>
        </el-col>
        <el-col :span="10">
          <el-radio v-model="addDialog.is_1V1" :label="2">是</el-radio>
          <el-radio v-model="addDialog.is_1V1" :label="1">否</el-radio>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">是否公开课：</span>
        </el-col>
        <el-col :span="10">
          <el-radio v-model="addDialog.is_open" :label="1">是</el-radio>
          <el-radio v-model="addDialog.is_open" :label="0">否</el-radio>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">是否精品：</span>
        </el-col>
        <el-col :span="10">
          <el-radio v-model="addDialog.is_best" :label="1">是</el-radio>
          <el-radio v-model="addDialog.is_best" :label="0">否</el-radio>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">是否热销：</span>
        </el-col>
        <el-col :span="10">
          <el-radio v-model="addDialog.is_hot" :label="1">是</el-radio>
          <el-radio v-model="addDialog.is_hot" :label="0">否</el-radio>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">是否新品：</span>
        </el-col>
        <el-col :span="10">
          <el-radio v-model="addDialog.is_new" :label="1">是</el-radio>
          <el-radio v-model="addDialog.is_new" :label="0">否</el-radio>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">是否上架：</span>
        </el-col>
        <el-col :span="10">
          <el-radio v-model="addDialog.is_enable" :label="1">上架</el-radio>
          <el-radio v-model="addDialog.is_enable" :label="0">下架</el-radio>
        </el-col>
      </el-row>

      <el-row v-if="addDialog.is_1V1 == 2">
        <el-col :span="4">
          <span style="line-height:50px">每节金币数：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="请输入每节课需要消耗的金币数"
            v-model="addDialog.score"
          ></el-input>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">选择老师：</span>
        </el-col>
        <el-col :span="20">
          <el-select
            style="height:50px;line-height:50px;width:100%"
            v-model="addDialog.teacher_id"
            placeholder="选择"
            v-loadmore="loadMoreChaptersListData"
          >
            <el-option
              v-for="item in selectTeacher"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

       <el-row>
        <el-col :span="4">
          <span style="height:50px;line-height:50px">详情：</span>
        </el-col>
        <el-col :span="20">
          <el-card style="height: 610px;">
                <quill-editor v-model="addDialog.content" ref="myQuillEditor" style="height: 500px;" :options="editorOption">
                </quill-editor>
              </el-card>
        </el-col>
      </el-row>
    <!-- </el-form> -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog.adddialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAddCourse">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 章节编辑 -->
    <el-dialog title="章节编辑" :visible.sync="editChapterDialog" style="padding:100px" width="50%">
          <el-form :model="editChapterForm" ref="editChapterForm">
            <el-form-item label="章节名称:" label-width="100px" prop="lib_name">
              <el-input placeholder="请输入章节名称" v-model="editChapterForm.lib_name" autocomplete="off"></el-input>
            </el-form-item>
            </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="editChapterDialog = false">取 消</el-button>
            <el-button type="primary" @click="editChapterNow">确 定</el-button>
          </span>
    </el-dialog>


    <!-- 小节编辑 -->
    <el-dialog title="小节编辑" :visible.sync="eddSectionDialog.editDialogVisiable" style="padding:100px" width="50%">
          <el-row>
            <el-col :span="4">
              <span style="line-height:50px">小节名称：</span>
            </el-col>
            <el-col :span="20">
              <el-input
                style="height:50px;line-height:50px"
                placeholder="请输入内容"
                v-model="eddSectionDialog.chapter_name"
              ></el-input>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0 12px 0">
            <el-col :span="4">
              <span>选择文件：</span>
            </el-col>
            <el-col :span="20">
              <el-button
                class="avatar-uploader"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                @click="choiceM('video')"
                v-model="eddSectionDialog.file_id"
                >
                <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
                <video v-if="edit_video" :src="edit_video" controls class="avatar"></video>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <p style="margin-top:10px">{{check_edit_file_name}}</p>
              </el-button>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="4">
              <span style="line-height:50px">解锁时间:</span>
            </el-col>
            <el-col :span="20">
              <el-date-picker
                    v-model="eddSectionDialog.unlock_time"
                    type="datetime"
                    value-format="timestamp"
                    placeholder="选择时间"
                    :picker-options="pickerOptions1"
                    >
                  </el-date-picker>
            </el-col>
          </el-row>

          <el-row>
           <el-col :span="4">
             <span style="line-height:50px">有无作业：</span>
           </el-col>
            <el-col :span="20">
             <el-select
                v-model="eddSectionDialog.is_task"
                placeholder="请选择有无作业"
                clearable
              >
                <el-option
                  :label="item.value"
                  :value="item.key"
                  v-for="item in isTaskArr"
                  :key="item.key"
                ></el-option>
              </el-select>
           </el-col>
         </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click="eddSectionDialog.editDialogVisiable = false">取 消</el-button>
            <el-button type="primary" @click="handleEditSection()">确 定</el-button>
          </span>
    </el-dialog>

    <!-- 章节大弹框 -->
    <el-dialog  :lock-scroll="true" :visible.sync="eddChapterDialog.editchapterVisible" width="90%" :close-on-click-modal=false @close="closedChapter">
       <div class="top_btn_box">
          <div class="top_btn_left">
            <div class="titles">{{courseName}}</div>
          <div class="cateButton" @click="addChapter.adddialogVisible = true">添加章节</div>
          </div>
          <el-button :class="isSectionDisabled?'not_sectionButton':'sectionButton'" :disabled="isSectionDisabled" @click="addSection">添加小节</el-button>
       </div>
      <div class="flex_box">

      <div class="flex-left">

        <el-table
          :data="chapterTable.data"
          class="table"
          :highlight-current-row="true"
          @row-click="getSectionData"
          border
        >
          <el-table-column prop="lib_name" label="章节名" align="center">
          </el-table-column>
           <el-table-column prop="orderby" label="排序" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="warning"
                icon="el-icon-sort"
                size="small"
                circle
                @click="toSet('chapter',scope.row)"
              ></el-button>
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="small"
                circle
                @click="eddChapter(scope.row)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="small"
                circle
                @click="removeTab(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- <div class="cateButton" @click="addChapter.adddialogVisible = true">添加章节</div> -->
      </div>

      <div class="flex-right">

        <el-table
          :data="sectionTable.data"
          border
          class="table"
          style="width: 100%;"
          ref="multipleSelection"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="chapter_name" label="小节名" align="center">
          </el-table-column>
          <el-table-column label="解锁时间" align="center" width="200">

            <template slot-scope="scope">
              <div v-if="scope.row.unlock_time !== null">
                {{
                  $moment(scope.row.unlock_time * 1000).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                }}
              </div>
              <div v-else>空</div>
            </template>
          </el-table-column>
           <el-table-column prop="orderby" label="排序" width="100" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope">
              <el-button
                type="warning"
                icon="el-icon-sort"
                size="small"
                circle
                @click="toSet('section',scope.row)"
              ></el-button>
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="small"
                circle
                @click="handleItemSectionEdit(scope.$index, scope.row)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="small"
                circle
                @click="handleItemSectionDelete(scope.$index, scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
          <!-- <el-button class="sectionButton" @click="addSection">添加小节</el-button> -->
        </div>

       </div>
    </el-dialog>

    <!-- 排序弹框 -->
    <el-dialog
      title="排序"
      :visible.sync="setDialog"
      width="30%">
       <el-form :model="setForm" ref="setForm" :rules="rules">
            <el-form-item label="排序值:" label-width="100px" prop="orderby">
              <el-input placeholder="请输入排序值" v-model.number="setForm.orderby" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setDialog = false">取 消</el-button>
        <el-button type="primary" @click="setNow">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import store from "@/store";
import { timestampToTime } from "@/utils/timeUtils";
import { warningDialog } from "@/utils/dialog";
import {
  courseUpdate,
  selectCourseCate,
  delCourse,
  getCourseList,
  delMoreCourse,
  addCourse,
  getCateList,
  getGoodsDetails,
  goodsChapterOrder,
  goodsLibOrder,
  examAnsDetailByVideo,
} from "@/api/courseApi";
import {
  getChapterList,
  editChapters,
  addChapter,
  delChapter,
  getSectionList,
  addSection,
  editSections,
  delSection,
} from "@/api/chapter";
import { selectMaterial, getTeacherList } from "@/api/select";
import {
  getFile,
  getMaterial,
  // selectMaterial
} from "@/api/material";
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import chapter from "../../components/chapter.vue";
import LoginVue from "../Login.vue";
export default {
  name: "couselist",
  components: {
    quillEditor,
    chapter,
  },
  data() {
    return {
      loading: false,
      goods_id: "",
      cateTable: {
        tableData: [],
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      courseTable: {
        title: "",
        goods_id: "",
        page: 1,
        limit: 10,
        pageIndex: 1,
        total: 0,
        data: [],
        search_date: [],
      },
      classification: null,
      //课程分类
      courseCate: [],
      select: {
        id: "",
        title: "",
        isSelect: false,
      },
      //选择老师
      selectTeacher: [],
      course: {
        title: "",
        data: [],
        isSelect: false,
      },
      // 添加课程
      addDialog: {
        adddialogVisible: false,
        cate_id: "",
        goods_name: "",
        desc: "",
        content: "",
        price: "",
        thumb_id: "",
        file_id: "",
        expire: null,
        views_vt: "",
        buy_num_vt: "",
        is_open: 0,
        is_best: 1,
        is_hot: 1,
        is_new: 1,
        is_enable: 1,
        is_1V1: 1,
        teacher_id: null,
        addName: "",
        selectItemIds: [],
        chapterIds: [],
        score: null,
      },

      //编辑
      eddDialog: {
        id: null,
        cate_id: "",
        desc: "",
        content: "",
        price: "",
        thumb_id: "",
        file_id: "",
        expire: "",
        views_vt: "",
        buy_num_vt: "",
        is_open: null,
        is_best: null,
        is_hot: null,
        is_new: null,
        is_enable: null,
        is_1V1: null,
        teacher_id: null,
        editVisible: false,
        goods_name: "",
        edStatusSelId: null,
        chapterIds: [],
        score: null,
      },
      eddChapterDialog: {
        editchapterVisible: false,
      },
      times: null,
      // content: null,
      editorOption: {},
      queryparms: {
        page: 1,
        limit: 12,
        file_name: null,
        file_type: null,
        cate_id: null,
        teacher: null,
      },
      checkedList: [],
      list: [],
      popup: false,
      popup2: false,
      materialList: "",
      ruleForm: {
        name: "",
      },
      editForm: {
        title: "",
        id: null,
      },
      unpdateDialog: false,
      cur: -1,
      tabs: 0,
      selecteds: "",
      selecteds2: "",
      selecteds3: "",
      // 缩略图
      imageUrl: "",
      //选中缩略图文件名称
      check_edit_file_name: "",
      check_add_file_name: "",
      //选中添加的课程视频
      check_video: "",
      //编辑小节中的视频
      edit_video: "",
      selectid: [],
      selectitem: [],
      // 素材
      imageUrl2: "",
      selectid2: [],
      selectitem2: [],

      //编辑章节
      editChapterDialog: false,
      editChapterForm: {
        id: "",
        lib_name: "",
      },
      //编辑小节
      eddSectionDialog: {
        edSectionId: null,
        editDialogVisiable: false,
        lib_id: 1,
        chapter_name: "",
        lib_name: "",
        thumb_id: "",
        unlock_time: "",
        is_task: 1,
      },
      editSectionDialog: {
        id: -1,
        editDialogVisiable: false,
      },
      chapterTable: {
        id: "",
        pageIndex: 1,
        limit: 5,
        pageTotal: 0,
        data: [],
      },
      sectionTable: {
        pageIndex: 1,
        limit: 5,
        pageTotal: 0,
        data: [],
      },
      select: {
        name: "",
        teacherSelId: "",
        statusSelId: "",
        isSelect: false,
        status: [
          {
            value: 1,
            name: "正常",
          },
          {
            value: 0,
            name: "禁止",
          },
        ],
      },
      // 添加章节
      addChapter: {
        id: "",
        adddialogVisible: false,
        goods_id: "",
        lib_name: "",
      },
      goodsDetailsList: "",
      // 添加小节
      addSectionDialog: {
        addSectionVisible: false,
        lib_id: "",
        chapter_name: "",
        file_id: "",
        thumb_id: "",
        unlock_time: "",
        is_task: 1,
      },
      eddChapterDialog: {
        editchapterVisible: false,
        goods_id: "",
      },
      // 素材弹窗
      rules: {
        file_name: [
          {
            required: true,
            message: "素材名称不能为空",
            trigger: "blur",
          },
          {
            min: 1,
            max: 8,
            addMaterialmessage: "长度在 1 到 8 个字符",
            trigger: "blur",
          },
        ],
        orderby: [
          {
            required: true,
            message: "排序值不能为空",
            trigger: "blur",
          },
          { type: "number", message: "排序值必须为数字值" },
        ],
      },
      checkedList: [],
      list: [],
      popup: false,
      materialList: "",
      ruleForm: {
        name: "",
      },
      editForm: {
        title: "",
        id: null,
      },
      goodsDetailsLists: "",
      unpdateDialog: false,
      cur: -1,
      tabs: 0,
      tab: [
        { name: "全部", file_type: null },
        { name: "图片", file_type: "image" },
        { name: "视频", file_type: "video" },
        { name: "音频", file_type: "audio" },
      ],
      // fileUrl: '',
      selectid: [],
      selectitem: [],
      MaterialLists: [],
      // 选择素材分页变量
      sucai: {
        // cate_id:null,
        file_type: null,
        // file_name:null
        page: 1,
        limit: 15,
      },

      materialListes: [],
      courseName: "",
      addImages: null,
      SectionImageUrl: null,
      editVisibleLoading: false,
      editVisibleText: "拼命加载中",
      setDialog: false,
      setForm: {
        id: null,
        orderby: null,
        target: null,
      },

      // choiceMaterialForm:{
      //   page:1,
      //   limit:15,
      // },
      choiceTotal: 0,
      detailsData: {
        cate: {},
      }, //详情数据
      detailsDialog: false, //详情弹框
      studentsDialog: false,//班级学生列表弹窗
      studentDetailDialog: false,//学生详情弹窗
      workDetailDialog: false,// 作业详情弹窗
      isTaskArr: [
        { key: 0, value: "无作业" },
        { key: 1, value: "有作业" },
      ],
      isSectionDisabled: true,
      getMaterialDate: {
        mold: ""
      },
      course_id: null,
      student_userid: null,
      studentDetailsData: [],
      workDetailsData: [],
      teacherReply: "",
      reply_message: "",
      workInfo: {},
      is_reply: null,
      exhibition: false,
      ansPic: "",
      teacher_id: "",
    };
  },
  methods: {
    // 打开排行榜链接
    openLink(link) {
      window.open(link);
    },
    // 获取老师列表
    getTeacherListData(mold) {
      getTeacherList({ mold }).then((res) => {
        this.selectTeacher = res.data;
      });
    },
    Input() {
      this.getMaterialClassification();
    },
    // 复制课程
    toCopyCourse(row){
      console.log(row)
      this.$confirm('确定复制本节课程?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let copyData = {
          op: "copy",
          goods_id: row.id
        }
        addCourse(copyData).then((res) => {
          if (res.code !== 1) {
            this.$message.error("添加课程失败,请稍后再试");
            return;
          }
          this.$message.success("复制成功!");
          this.getCourseData();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消复制'
        });          
      });
    },
    // 查看详情
    toViewDetails(row,num) {
      this.course_id = row.id;
      getGoodsDetails({ id: row.id }).then((res) => {
        if (res.code == 1) {
          this.detailsData = res.data;
        }
      });
      if(num == 1){
        //详情
        this.detailsDialog = true;
      }else if(num == 2){
        //班级学生列表
        this.studentsDialog = true;
      }
    },
    // 学生学习进度表
    toStudentDetail(row){
      this.student_userid = row.user_id;
      getGoodsDetails({ id: this.course_id , user_id: row.user_id}).then((res) => {
        if (res.code == 1) {
          this.studentDetailsData = res.data;
        }
      });
      this.studentDetailDialog = true;
    },
    // 作业完成情况
    toWorkDetail(row){
      this.workInfo = {
        file_id: row.goodsLibChapter.file_id,
        user_id: row.user_id,
        goods_id: row.goods_id,
        lib_id: row.lib_id,
        chapter_id: row.chapter_id,
        type: 2
      }
      this.getWorkDetailInfo();
      this.workDetailDialog = true;
    },
    getWorkDetailInfo(){
      examAnsDetailByVideo(this.workInfo).then((res) => {
        if (res.code == 1) {
          console.log(res.data.message)
          this.reply_message = res.data.message.reply_message;
          this.teacherReply = res.data.message.teacher_message;
          this.is_reply = res.data.message.reply;
          delete res.data.message;
          this.workDetailsData = res.data;
        }
      });
    },
    // 点击回复按钮
    reply(){
      let data = {
        ...this.workInfo,
        op: "message",
        message: this.teacherReply
      }
      examAnsDetailByVideo(data).then((res) => {
        if (res.code == 1) {
          this.$message.success("回复成功!");
          getGoodsDetails({ id: this.course_id , user_id: this.student_userid}).then((res) => {
            if (res.code == 1) {
              this.studentDetailsData = res.data;
            }
          });
          getGoodsDetails({ id: this.course_id }).then((res) => {
            if (res.code == 1) {
              this.detailsData = res.data;
            }
          });
        }else{
          this.$message.error(res.msg);
        }
      });
      this.workDetailDialog = false;
      this.getWorkDetailInfo();
    },
    // 点击学生答题图片弹窗
    exhibitionSay(elm) {
      this.exhibition = true;
      this.ansPic = elm;
    },
    onEditorFocus(event) {
      event.enable(false);
    },
    toSet(target, row) {
      this.setForm.id = row.id;
      this.setForm.orderby = row.orderby;
      this.setForm.target = target;
      this.setDialog = true;
    },
    setNow() {
      this.$refs.setForm.validate((valid) => {
        if (valid) {
          let data = {
            id: this.setForm.id,
            orderby: this.setForm.orderby,
          };
          if (this.setForm.target == "chapter") {
            goodsLibOrder(data).then((res) => {
              if (res.code !== 1) {
                this.$message.error(res.msg);
                return;
              }
              this.$message.success("编辑成功!");
              this.setDialog = false;
              this.getChapterData();
            });
          } else {
            goodsChapterOrder(data).then((res) => {
              if (res.code !== 1) {
                this.$message.error(res.msg);
                return;
              }
              this.$message.success("编辑成功!");
              this.setDialog = false;
              this.getlibIds();
            });
          }
        } else {
          return false;
        }
      });
    },
    // 选择老师
    loadMoreTeacherListData() {
      this.teachers.page++;
      this.handleGetTeacherList();
    },
    searchCouseList() {
      this.courseTable.page = 1;
      this.getCourseData();
    },
    handleSizeChange(size) {
      this.courseTable.limit = size;
      this.getCourseData();
    },
    handleCurrentChange(page) {
      this.courseTable.page = page;
      this.getCourseData();
    },

    //获取课程列表
    getCourseData() {
      this.loading = true;
      let start_time = 0;
      let end_time = 0;
      if (this.courseTable.search_date != null) {
        if (this.courseTable.search_date.length > 0) {
          start_time = Math.round(
            new Date(this.courseTable.search_date[0] + " 00:00:00").getTime() /
              1000
          );
          end_time = Math.round(
            new Date(this.courseTable.search_date[1] + " 23:59:59").getTime() /
              1000
          );
        }
      }

      var params = {
        goods_name: this.courseTable.title,
        page: this.courseTable.page,
        limit: this.courseTable.limit,
        start_time: start_time,
        end_time: end_time,
        cate_id: this.classification,
        teacher_id: this.teacher_id,
      };
      this.select.isSelect = false;
      getCourseList(params).then((res) => {
        this.courseTable.data = [];
        this.courseTable.data = res.data.data;
        console.log(this.courseTable.data)
        this.courseTable.total = res.data.total;
        this.loading = false;
      });
      // this.getMaterialList()
    },
    toTime(timeStr) {
      return timestampToTime(parseInt(timeStr));
    },
    // 时间戳
    onSubmit() {
      if (this.addDialog.expire) {
        this.addDialog.expire =
          new Date(this.addDialog.expire[0]).getTime() / 1000;
      }
      if (this.eddDialog.expire) {
        this.eddDialog.expire =
          new Date(this.eddDialog.expire[0]).getTime() / 1000;
      }
      return this.addDialog.expire;
      return this.eddDialog.expire;
    },
    loadMoreChaptersListData() {
      this.courseTable.data.currentPage++;
    },
    //选择分类

    handleItemDelete(index, row) {
      warningDialog("确定要删除吗？").then(() => {
        //删除操作
        this.delCourse(index);
      });
    },
    getMaterialList() {
      selectMaterial(this.queryparms).then((res) => {
        // this.total=res.data.total;
        this.materialList = res.data;
      });
    },

    //删除课程
    delCourse(index) {
      delCourse(this.courseTable.data[index].id).then((res) => {
        if (res == undefined) {
          return;
        }
        if (res.code === 1) {
          this.$message.success("删除课程成功!");
          this.getCourseData();
        }
      });
    },
    //编辑课程
    async handleItemEdit(index, row) {
      this.editVisibleLoading = true;
      await getGoodsDetails({ id: row.id }).then((res) => {
        console.log(res.data);
        this.eddDialog.cate_id = res.data.cate.id;
        this.imageUrl2 = res.data.thumb.avthumb;
        // this.eddDialog.teachers = res.data.teacher;
        this.eddDialog.teacher_id = res.data.teacher_id;
        this.eddDialog.content = res.data.content;
        this.editVisibleLoading = false;
      });

      this.eddDialog.editVisible = true;
      this.eddDialog.id = this.courseTable.data[index].id;
      // this.eddDialog.cate_id = this.courseTable.data[index].cate_id;
      this.eddDialog.goods_name = this.courseTable.data[index].goods_name;
      this.eddDialog.desc = this.courseTable.data[index].desc;
      var goodsDetailsListss = localStorage.getItem("detail");
      // this.eddDialog.content = goodsDetailsListss;
      // let contentRs = this.eddDialog.content;
      this.eddDialog.price = this.courseTable.data[index].price;
      this.eddDialog.thumb_id = this.courseTable.data[index].thumb_id;
      this.eddDialog.file_id = this.courseTable.data[index].file_id;
      this.eddDialog.expire = this.courseTable.data[index].expire * 1000;
      this.eddDialog.views_vt = this.courseTable.data[index].views_vt;
      this.eddDialog.buy_num_vt = this.courseTable.data[index].buy_num_vt;
      this.eddDialog.is_open = this.courseTable.data[index].is_open;
      this.eddDialog.is_best = this.courseTable.data[index].is_best;
      this.eddDialog.is_hot = this.courseTable.data[index].is_hot;
      this.eddDialog.is_new = this.courseTable.data[index].is_new;
      this.eddDialog.is_enable = this.courseTable.data[index].is_enable;
      this.eddDialog.is_1V1 = this.courseTable.data[index].type;
      this.eddDialog.score = this.courseTable.data[index].score;
      // this.eddDialog.teacher = this.courseTable.data[index].teacher;
      let taeacherId = this.eddDialog.teacher_id;
      return this.eddDialog.id;
    },

    // 编辑课程
    editCourse() {
      this.editVisibleLoading = true;
      this.editVisibleText = "修改中,请稍后";
      var data = {
        id: this.eddDialog.id,
        cate_id: this.eddDialog.cate_id,
        goods_name: this.eddDialog.goods_name,
        desc: this.eddDialog.desc,
        content: this.eddDialog.content,
        price: this.eddDialog.price,
        thumb_id: this.eddDialog.thumb_id,
        file_id: this.eddDialog.file_id,
        expire: this.eddDialog.expire / 1000,
        views_vt: this.eddDialog.views_vt,
        buy_num_vt: this.eddDialog.buy_num_vt,
        is_open: this.eddDialog.is_open,
        is_best: this.eddDialog.is_best,
        is_hot: this.eddDialog.is_hot,
        is_new: this.eddDialog.is_new,
        is_enable: this.eddDialog.is_enable,
        teacher_id: this.eddDialog.teacher_id,
        type: this.eddDialog.is_1V1,
      };
      if(data.type == 2){
        data.score = this.eddDialog.score;
      }
      if (data.cate_id == "") {
        this.$message.error("请选择课程分类");
        return;
      } else if (data.goods_name == "") {
        this.$message.error("请输入课程名称");
        return;
      } else if (data.desc == "") {
        this.$message.error("请输入课程描述");
        return;
      } else if (data.thumb_id == "") {
        this.$message.error("请选择课程封面");
        return;
      } else if (data.expire == "") {
        this.$message.error("请选择课程有效期");
        return;
      } else if (data.teacher_id == "" || data.teacher_id == null) {
        this.$message.error("请选择课程授课教师");
        return;
      } else if (data.content == "") {
        this.$message.error("请输入课程详情");
        return;
      }
      console.log(data)
      courseUpdate(data).then((res) => {
        this.editVisibleLoading = false;
        this.editVisibleText = "拼命加载中";
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.eddDialog.editVisible = false;
        this.$message.success("编辑课程成功!");
        this.getCourseData();
      });
    },

    handleAvatarSuccess(res) {
      this.imageUrl = URL.createObjectURL(index.raw);
    },

    delAllSelection() {
      var _this = this;
      warningDialog("确定删除该课程？").then(() => {
        _this.delSelectCourse();
      });
    },
    delSelectCourse() {
      var idsStr = "";
      for (let i = 0; i < this.addDialog.selectItemIds.length; i++) {
        if (i == 0) {
          idsStr = this.addDialog.selectItemIds[i].id + "";
        } else {
          idsStr += "," + this.addDialog.selectItemIds[i].id;
        }
      }
      var data = {
        id: idsStr,
      };

      delMoreCourse(data).then((res) => {
        this.getCourseData();
      });
    },
    handleSelectionChange(val) {
      this.addDialog.selectItemIds = val;
    },

    toAdd() {
      this.addImages = "";
      this.imageUrl = "";
      this.imageUrl2 = "";
      this.addDialog.adddialogVisible = true;
    },
    getSelectCourseCate() {
      selectCourseCate().then((res) => {
        this.courseCate = res.data;
      });
    },

    //添加课程
    handleAddCourse() {
      var data = {
        cate_id: this.addDialog.cate_id,
        goods_name: this.addDialog.goods_name,
        desc: this.addDialog.desc,
        content: this.addDialog.content,
        price: this.addDialog.price,
        thumb_id: this.addDialog.thumb_id,
        file_id: this.addDialog.file_id,
        expire: this.addDialog.expire / 1000,
        views_vt: this.addDialog.views_vt,
        buy_num_vt: this.addDialog.buy_num_vt,
        is_open: this.addDialog.is_open,
        is_best: this.addDialog.is_best,
        is_hot: this.addDialog.is_hot,
        is_new: this.addDialog.is_new,
        is_enable: this.addDialog.is_enable,
        teacher_id: this.addDialog.teacher_id,
        type: this.addDialog.is_1V1,
      };

      if(data.type == 2){
        data.score = this.addDialog.score;
      }

      if (data.cate_id == "") {
        this.$message.error("请选择课程分类");
        return;
      } else if (data.goods_name == "") {
        this.$message.error("请输入课程名称");
        return;
      } else if (data.desc == "") {
        this.$message.error("请输入课程描述");
        return;
      } else if (data.thumb_id == "") {
        this.$message.error("请选择课程封面");
        return;
      } else if (data.expire == "") {
        this.$message.error("请选择课程有效期");
        return;
      } else if (data.type == 2 && data.score == null) {
        this.$message.error("请输入每节课需要消耗的金币数");
        return;
      } else if (data.teacher_id == "" || data.teacher_id == null) {
        this.$message.error("请选择课程授课教师");
        return;
      } else if (data.content == "") {
        this.$message.error("请输入课程详情");
        return;
      }
      addCourse(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error("添加课程失败,请稍后再试");
          return;
        }
        this.addDialog.adddialogVisible = false;
        this.$message.success("添加成功!");
        this.getCourseData();
      });
    },

    //章节弹窗
    handleItemChapter(index, row) {
      this.courseName = row.goods_name;
      this.eddChapterDialog.editchapterVisible = true;
      this.eddChapterDialog.goods_id = this.courseTable.data[index].id;
      var params = {
        goods_id: this.eddChapterDialog.goods_id,
      };
      getChapterList(params).then((res) => {
        this.chapterTable.data = res.data;
        this.pageTotal = res.data.totalRows;
        this.getMaterialList();
      });
    },
    getChapterData() {
      var params = {
        goods_id: this.eddChapterDialog.goods_id,
      };
      getChapterList(params).then((res) => {
        this.chapterTable.data = res.data;
        this.pageTotal = res.data.totalRows;
        this.getMaterialList();
      });
    },

    getlibIds() {
      var libId = sessionStorage.getItem("libId");
      var params = {
        lib_id: libId,
      };
      getSectionList(params).then((res) => {
        this.sectionTable.data = res.data;
        // console.log(this.sectionTable.data,"this.sectionTable.data")
        this.sectionTable.pageTotal = res.data.totalRows;
      });
    },

    getSectionData(row, column, event) {
      sessionStorage["libId"] = row.id;
      this.selecteds3 = row;
      this.isSectionDisabled = false;
      this.getlibIds();
    },

    // 编辑章节
    eddChapter(item) {
      this.editChapterForm.id = item.id;
      this.editChapterForm.lib_name = item.lib_name;
      this.editChapterDialog = true;
    },
    // 点击立即编辑章节
    editChapterNow() {
      this.$refs.editChapterForm.validate((valid) => {
        if (valid) {
          editChapters(this.editChapterForm).then((res) => {
            if (res.code !== 1)
              return this.$message.error("编辑章节失败,请重试");
            this.$message.success("编辑章节成功！");
            this.editChapterDialog = false;
            this.getChapterData();
          });
        } else {
          return false;
        }
      });
    },
    // 点击item编辑小节
    handleItemSectionEdit(index, row) {
      console.log(row,"row")
      this.imageUrl = row.file.avthumb
      this.edit_video = row.file.key;
      // console.log(this.edit_video)
      this.check_edit_file_name = row.file.file_name;
      this.eddSectionDialog.lib_id = this.sectionTable.data[index].id;
      this.eddSectionDialog.chapter_name =
        this.sectionTable.data[index].chapter_name;
      this.eddSectionDialog.file_id = this.sectionTable.data[index].file_id;
      this.eddSectionDialog.unlock_time =
        this.sectionTable.data[index].unlock_time * 1000;
      this.eddSectionDialog.editDialogVisiable = true;
      this.eddSectionDialog.is_task = this.sectionTable.data[index].is_task;
    },
    closedChapter() {
      this.sectionTable.data = [];
      this.selecteds3 = "";
      this.isSectionDisabled = true;
    },
    // 保存item编辑小节
    handleEditSection() {
      this.eddSectionDialog.editDialogVisiable = false;
      var data = {
        id: this.eddSectionDialog.lib_id,
        chapter_name: this.eddSectionDialog.chapter_name,
        file_id: this.eddSectionDialog.file_id,
        unlock_time: this.eddSectionDialog.unlock_time / 1000,
        is_task: this.eddSectionDialog.is_task,
      };
      editSections(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改成功!");
        this.getlibIds();
      });
    },

    //删除章节操作
    handleItemCapterDelete(index, row) {
      warningDialog("确定要删除吗？").then(() => {
        //删除操作
        this.delChapter(index);
      });
    },
    //删除小节操作
    handleItemSectionDelete(index, row) {
      warningDialog("确定要删除吗？").then(() => {
        //删除操作
        this.delSection(index);
      });
    },

    //删除章节
    delChapter(index, row) {
      delChapter(this.chapterTable.data[index].id).then((res) => {
        this.getChapterData();
      });
    },
    //删除小节
    delSection(index) {
      delSection(this.sectionTable.data[index].id).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除小节成功");
        this.getlibIds();
      });
    },
    delAllSelection() {
      var _this = this;
      warningDialog("确定要删除吗？").then(() => {
        _this.delSelectSection();
      });
    },

    async removeTab(id) {
      const result = await this.$confirm(
        "请问是否要永久删除该章节?",
        "删除提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (result !== "confirm") return this.$message.info("您已经撤销删除");

      var libId = sessionStorage.getItem("libId");
      var params = {
        lib_id: libId,
      };
      getSectionList(params).then((res) => {
        if(res.data.length > 0){
          this.askSecond(id)
        }else{
          delChapter(id).then((res) => {
            if (res == undefined) {
              return;
            }
            if (res.code === 1) {
              this.getChapterData();
              this.$message.success("删除章节成功");
            }
          });
        }
      });

      // delChapter(id).then((res) => {
      //   if (res == undefined) {
      //     return;
      //   }
      //   if (res.code === 1) {
      //     this.getChapterData();
      //     this.$message.success("删除章节成功");
      //   }
      // });
    },
    async askSecond(id){
      const result = await this.$confirm(
        "该章节存在未删除小结，请确认删除",
        "删除提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (result !== "confirm") return this.$message.info("您已经撤销删除");

      delChapter(id).then((res) => {
        if (res == undefined) {
          return;
        }
        if (res.code === 1) {
          this.getChapterData();
          this.sectionTable.data = [];
          this.$message.success("删除章节成功");
        }
      });
    },
    handleSelectionChange(val) {
      this.addDialog.selectItemIds = val;
    },
    handlePageCurrpageChange(val) {
      this.sectionTable.pageIndex = val;
      if (this.isSelect) {
        this.selectSectionList();
      } else {
        this.getChapterData();
      }
    },
    handlelimitChange(val) {
      this.sectionTable.limit = val;
      if (this.isSelect) {
        this.getChapterData();
      } else {
        this.selectSectionList();
      }
    },
    //添加章节
    handleAddChapter(item) {
      // var goods_id = item.id;
      this.addChapter.adddialogVisible = false;
      var data = {
        goods_id: this.eddChapterDialog.goods_id,
        lib_name: this.addChapter.addChapterName,
      };
      addChapter(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加章节成功!");
        this.getChapterData();
      });
    },
    // 添加小节
    handleAddSection() {
      var libId = sessionStorage.getItem("libId");
      this.addSectionDialog.addSectionVisible = false;
      var data = {
        lib_id: libId,
        chapter_name: this.addSectionDialog.chapter_name,
        file_id: this.addSectionDialog.file_id,
        unlock_time: this.addSectionDialog.unlock_time / 1000,
        is_task: this.addSectionDialog.is_task,
      };
      addSection(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加小节成功!");
        this.getlibIds();
      });
    },

    // 素材弹窗
    checkTab(id) {
      let index = this.checkedList.indexOf(id);
      if (index === -1) {
        this.checkedList.push(id);
      } else {
        this.checkedList.splice(index, 1);
      }
    },
    selectImg(item) {
      console.log(item,"item")
      this.selecteds = item;
      this.selectid = item.id;
      this.selectitem = item.avthumb;
      this.SectionImageUrl = item.avthumb;
      this.check_video = item.playUrl;
      console.log(this.check_video)
      this.check_add_file_name = item.file_name;
    },

    selectImg2(item) {
      this.selecteds2 = item;
      this.selectid2 = item.id;
      this.selectitem2 = item.avthumb;
      this.addImages = item.avthumb;
    },

    // 素材弹窗分类切换
    toggleClass(i, item) {
      this.cur = i;
      this.queryparms.cate_id = item;
      this.addFileForm.cate_id = item;
      this.addAvidoForm.cate_id = item;
      this.getMaterialList();
    },
    toggletabs(i, elm) {
      this.tabs = i;
      this.queryparms.file_type = elm;
      this.getMaterialList();
    },
    getMaterialList() {
      selectMaterial(this.queryparms).then((res) => {
        this.total = res.data.total;
        this.materialList = res.data;
      });
    },
    toQuery() {
      this.getMaterialList();
    },

    choiceM(type) {
      if (type == null) {
        this.popup = true;
        this.sucai.file_type = null;
        this.getMaterialClassification();
      }
      if (type == "image") {
        this.popup2 = true;
        this.sucai.file_type = "image";
        this.getMaterialClassification();
      }
      if (type == "video") {
        this.popup = true;
        this.sucai.file_type = "video";
        this.getMaterialClassification();
      }
    },
    // 下拉选择素材分类时触发
    getMaterialClassification() {
      // this.sucai.page = 1;
      let role = localStorage.getItem("role")
      if(role == 4){
        this.getMaterialDate.mold = "japanese"
      }else if(role == 5){
        this.getMaterialDate.mold = "korean"
      }else if(role == 6){
        this.getMaterialDate.mold = "french"
      }else if(role == 7){
        this.getMaterialDate.mold = "spanish"
      }else if(role == 8){
        this.getMaterialDate.mold = "german"
      }
      console.log(role,this.getMaterialDate)
      getMaterial(this.getMaterialDate).then((res) => {
        this.MaterialLists = res.data;
        console.log(this.MaterialLists)
        this.sucai.mold = this.getMaterialDate.mold;
        console.log(this.sucai)
        selectMaterial(this.sucai).then((res) => {
          this.materialListes = res.data.data;
          this.choiceTotal = res.data.total;
          console.log(this.materialListes)
        });
      });
    },

    // 选择素材分页条数
    choiceSizeChange(limit) {
      this.sucai.limit = limit;
      selectMaterial(this.sucai).then((res) => {
        this.materialListes = res.data.data;
        this.choiceTotal = res.data.total;
      });
    },
    choiceCurrentChange(page) {
      this.sucai.page = page;
      selectMaterial(this.sucai).then((res) => {
        this.materialListes = res.data.data;
        this.choiceTotal = res.data.total;
      });
    },

    addMaterial() {
      if (this.addSectionDialog.chapter_name == "") {
        this.addSectionDialog.chapter_name =
          this.selecteds.file_name.split(".")[0];
      }
      this.popup = false;
      this.eddDialog.file_id = this.selectid;
      this.addDialog.file_id = this.selectid;
      this.eddSectionDialog.file_id = this.selectid;
      this.addSectionDialog.file_id = this.selectid;
      this.imageUrl = this.selectitem;
    },

    addMaterial2() {
      this.popup2 = false;
      this.eddDialog.thumb_id = this.selectid2;
      this.addDialog.thumb_id = this.selectid2;
      this.imageUrl2 = this.selectitem2;
    },
    addSection() {
      if (this.selecteds3 == "") {
        this.$message.error("请选择章节分类!");
        return;
      }
      this.addSectionDialog.addSectionVisible = true;
    },
    refresh() {
      this.getCourseData();
    },
    closed() {
      this.addSectionDialog.chapter_name = "";
      this.imageUrl = "";
      this.addSectionDialog.unlock_time = "";
      this.SectionImageUrl = "";
      this.addSectionDialog.file_id = "";
      this.check_video = "";
      this.check_add_file_name = "";
      this.check_edit_file_name = "";
    },

    changeTeache() {
      this.$forceUpdate();
    },

    closedAdd(){
      this.addDialog = {
        adddialogVisible: false,
        cate_id: "",
        goods_name: "",
        desc: "",
        content: "",
        price: "",
        thumb_id: "",
        file_id: "",
        expire: null,
        views_vt: "",
        buy_num_vt: "",
        is_open: 0,
        is_best: 1,
        is_hot: 1,
        is_new: 1,
        is_enable: 1,
        is_1V1: 1,
        teacher_id: null,
        addName: "",
        selectItemIds: [],
        chapterIds: [],
        score: null,
      }
    },

    closeWorkDetailDialog(){
      this.workDetailsData = []
    }
  },
  created() {
    this.getSelectCourseCate();
  },
  mounted() {
    this.refresh();
  },
  computed: {
    selectArray() {
      return this.list.filter((e) => e.vv_selected).map((e) => e.id);
    },
  },
  watch: {
    "addDialog.cate_id": function (news, old) {
      this.courseCate.forEach((item) => {
        if (item.id == news) {
          this.getTeacherListData(item.mold);
          return;
        }
      });
    },
    "eddDialog.cate_id": function (news, old) {
      this.courseCate.forEach((item) => {
        if (item.id == news) {
          this.getTeacherListData(item.mold);
          return;
        }
      });
    },
    "classification": function (news, old) {
      this.teacher_id = null;
      this.courseCate.forEach((item) => {
        if (item.id == news) {
          this.getTeacherListData(item.mold);
          return;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.red {
  color: #ff0000;
}
.set {
  color: #ebb563;
}
.inputText {
  position: relative;
  display: inline-block;
}
.radiusbg {
  border-radius: 2px;
  background-color: #ffffff;
  padding: 0px 0px 10px 0px;
  margin-top: 10px;
}
.rightview {
  display: inline-block;
}
.table {
  margin-top: 20px;
  background: #ffffff;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-aside {
  color: #333;
}

li {
  list-style: none;
  cursor: pointer;
}

.left ul {
  font-size: 14px;
  color: #333333;
}
.left ul li {
  padding-left: 20px;
  line-height: 50px;
  border-bottom: 1px solid #eeeeee;
}
.left ul .selected {
  background-color: #f5f7fa;
}
.right {
  flex: 10;
  margin-left: 20px;
}
.headers {
  border-bottom: 1px solid #eeeeee;
  width: 100%;
  height: 40px;
}
.headers ul {
  width: 100%;
}
.headers .selected {
  color: #38a28a;
  border-bottom: 1px solid #38a28a;
}
.headers li {
  height: 40px;
  float: left;
  margin-right: 60px;
}
::v-deep .el-form-item__label {
  color: #333333;
}
.ck {
  float: right;
}
.ck i {
  margin-right: 10px;
}
.ck i:nth-child(1) {
  color: #3fbfa2;
}
.ck i:nth-child(2) {
  color: red;
}
.navs .search .inputs {
  width: 180px;
}
::v-deep .el-form-item__content {
  vertical-align: 0;
}

.search {
  display: flex;
  line-height: 70px;
}

.contents {
  width: 999px;
  padding: 20px;
}
.material {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 180px;
  margin: 0 20px 0 0;
  text-align: center;
}

.material .tag {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: #999;
  opacity: 0.8;
  color: #f5f7fa;
}
.material .active {
  border: 2px solid #2fe9fc;
  z-index: 1000;
}
.material img {
  width: 100%;
  height: 118px;
}
.material p {
  font-size: 14px;
  color: #333333;
  line-height: 26px;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.material .types {
  display: flex;
  justify-content: space-between;
}
.material .types .types-left img {
  width: 18px;
  height: 18px;
}
.material .types span {
  color: #969696;
  font-size: 12px;
  margin-left: 10px;
}
.pagination {
  display: flex;
  justify-content: space-between;
}

.types .types-right i:nth-child(1) {
  margin-right: 10px;
}
.types .types-right i:nth-child(2) {
  color: red;
}
.el-col {
  cursor: pointer;
}
.icon-shipin {
  color: #feaf65;
  font-size: 116px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.chapterBody {
  position: fixed;
  z-index: 1009;
  overflow: auto;
  left: 200px;
  -webkit-box-shadow: 0px 2px 5px #666666;
  box-shadow: 0px 2px 5px #666666;
}
.top_btn_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top_btn_left {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titles {
      padding-left: 26px;
    }
    .cateButton {
      width: 200px;
      background-color: #38a28a;
      text-align: center;
      height: 34px;
      color: #fff;
      padding-top: 14px;
    }
  }

  .sectionButton {
    width: 200px;
    background-color: #1aa779;
    display: inline-block;
    text-align: center;
    color: #fff;
  }
  .not_sectionButton {
    width: 200px;
    background-color: #eeeeee;
    display: inline-block;
    text-align: center;
    color: #323232;
    font-weight: bold;
  }
}

.handle-box {
  position: relative;
  margin-right: 10px;
}
.iconfont {
  color: #3dccab;
}
.flex_box {
  width: 100%;
  display: flex;
}
.flex-left {
  margin: 0 20px;
  width: 40%;
  height: 750px;
  height: auto;
  overflow-y: auto;
}

.flex-right {
  width: 60%;
  height: 750px;
  // height: auto;
  overflow-y: auto;
}

.cateHeader {
  background-color: #f5f7fa;
  height: 34px;
  padding-top: 14px;
  text-align: center;
}

.is-center li {
  height: 36px;
  padding: 14px 0 0 14px;
  border-bottom: 1px solid #ebeef5;
}
.is-center .active {
  background-color: #38a28a;
  color: #fff;
}
.red {
  color: #ff0000;
}
.inputText {
  position: relative;
  display: inline-block;
}
.radiusbg {
  border-radius: 2px;
  background-color: #ffffff;
  padding: 0px 0px 10px 0px;
  margin-top: 10px;
}
.rightview {
  display: inline-block;
}
.table {
  background: #ffffff;
  width: 100%;
}

li {
  list-style: none;
  cursor: pointer;
}

.left ul {
  font-size: 14px;
  color: #333333;
}
.left ul li {
  padding-left: 20px;
  line-height: 50px;
  border-bottom: 1px solid #eeeeee;
}
.left ul .selected {
  background-color: #f5f7fa;
}
.right {
  flex: 10;
  margin-left: 20px;
}
::v-deep .el-form-item__label {
  color: #333333;
}
.ck {
  float: right;
}
.ck i {
  margin-right: 10px;
}
.ck i:nth-child(1) {
  color: #3fbfa2;
}
.ck i:nth-child(2) {
  color: red;
}
.navs .search .inputs {
  width: 180px;
}
::v-deep .el-form-item__content {
  vertical-align: 0;
  /* height: 30px; */
}

.search {
  display: flex;
  /* justify-content: space-between; */
  line-height: 70px;
}
.search .search-left {
  margin-left: 50px;
}

.contents {
  width: 999px;
  padding: 20px;
}
.material {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 180px;
  margin: 0 20px 0 0;
  text-align: center;
}
.material img {
  width: 100%;
  height: 118px;
}
.material p {
  font-size: 14px;
  color: #333333;
  line-height: 26px;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.material .types {
  display: flex;
  justify-content: space-between;
}
.material .types .types-left img {
  width: 18px;
  height: 18px;
}
.material .types span {
  color: #969696;
  font-size: 12px;
  margin-left: 10px;
}
.pagination {
  display: flex;
  justify-content: space-between;
}

.types .types-right i:nth-child(1) {
  margin-right: 10px;
}
.types .types-right i:nth-child(2) {
  color: red;
}
.el-col {
  cursor: pointer;
}
.icon-shipin {
  color: #feaf65;
  font-size: 116px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.generate {
  float: right;
}

// ::v-deep .el-dialog {
//   margin-top: 10vh;
// }
.pagination {
  display: flex;
  justify-content: space-between;
}

.footer-btn {
  display: flex;
  justify-content: space-evenly;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 200px;
  height: 112px;
}
.detailImg/deep/img{
  width: 80%;
}
.questionAns {
  margin-top: 70px;
}
.questionBox {
  .questionNum {
    font-size: 16px;
    font-weight: bold;
  }
  .questionItem {
    div {
      font-size: 15px;
      width: 95%;
      // height: 35px;
      line-height: 35px;
      margin-bottom: 10px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
    }
    .op1{
      background-color: #f0f9eb;
      padding: 0px 10px;
      border: 1px solid #e1f3d8;
      color: #67c23a;
    }
    .op2{
      background-color: #fef0f0;
      padding: 0px 10px;
      border: 1px solid #fde2e2;
      color: #f56c6c;
    }
    .op3{
      background-color: #ffffff;
      padding: 0px 10px;
      border: 1px solid #eee;
    }
    i{
      font-size: 24px;
      line-height: 35px;
    }
    audio{
      margin:10px 0px;
    }
  }
  .questionItemText {
    .op4{
      font-size: 15px;
      width: 95%;
      // height: 35px;
      line-height: 35px;
      margin-bottom: 10px;
      border-radius: 5px;
    }
    .op5{
      font-size: 15px;
      width: 95%;
      height: 150px;
      margin-bottom: 10px;
      border-radius: 5px;
      border: 1px solid #eee;
      padding: 10px;
      overflow: auto;
    }
  }
}
.exhibition {
  width: 800px;
  // height: 600px;
  overflow: auto;
}
</style>
